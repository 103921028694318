<template>
	<div>
		<a-result status="403" title="到期" sub-title="很抱歉, 您的店铺已经过期.">
		    <template #extra>
		      <router-link to="/mendian">
		      <a-button type="primary">
		        返回
		      </a-button>
		      </router-link>
		    </template>
		  </a-result>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				
			}
		}
	}
</script>

<style>

</style>